.record {
  .MuiTableCell-sizeSmall {
    padding: 4px;
  }
  .MuiButton-root {
    margin: 0 4px;
  }
  .record-thumbnail {
    text-align: center;
    img {
      margin: auto;
    }
  }
  .deleted {
    td:not(.instance-action) {
      opacity: 0.3;
      background: #eee;
    }
  }
  .instance {
    & > * {
      border-bottom: unset;
    }
    // & > *:not(.instance-action) {
    // }
  }
}
