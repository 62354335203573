.records {
  .search-form {
    text-align: center;
  }
  .results {
    .results-total {
      text-align: center;
    }
  }
}
