.App {
  .EdgeHeaderOffset {
    flex: none;
  }
  .MuiContainer-root {
    padding-top: 8px;
    .MuiPaper-root {
      padding: 10px;
    }

  }
  .topnav {
    .logout,
    .logout .MuiTypography-root {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      .material-icons {
        font-size: initial;
        margin-right: 5px;
      }
    }
    .logout {
      flex: 0 0 auto;
      white-space: nowrap;
      padding-left: 24px;
    }
  }
}
