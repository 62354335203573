.form {
  .buttons {
    text-align: right;
  }
  .select-input {
    .MuiInputBase-input {
      // padding: 0;
      &>div {
        border: none;
        background-color: inherit;
      }
    }
    .MuiOutlinedInput-input {
      height: auto;
    }
  }
  .read-only {
    .MuiChip-root.Mui-disabled {
      opacity: 1;
    }
    .MuiAutocomplete-hasPopupIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
      padding-right: 9px;
    }
    .MuiAutocomplete-endAdornment, .MuiChip-deleteIcon {
      display: none;
    }
  }
}
